import React, { FC, Fragment, ReactNode } from "react";

import Image from "next/image";
import { Typography } from "../../../ui-kit/Typography";
import Gutter from "../../../ui-kit/Gutter";
import { useWebTranslation } from "../../../translations";
import styles from "./InsurancePackage.module.css";
import { Button } from "../../../ui-kit/Button";
import Colors from "../../../ui-kit/colors";
import { FlexCol, FlexRow } from "../../../ui-kit/Layout/Flex";
import PackageCard from "components-shared/PackageCard";
import PackageCardContent from "components-shared/PackageCardContent";
import {
  Package,
  PackageConfig,
} from "components/ContentfulRenderer/ContentfulPackages/types";
import { AmountToPay } from "utils/insurance/getAmountToPay";
import { Trans } from "next-i18next";
import { formatCurrency } from "utils/utils";

type InsurancePackageProps = {
  style?: any;
  onPress?: () => void;
  onInfo?: ({ title, content }: { title: string; content: ReactNode }) => void;
  onCompare: () => void;
  active?: boolean;
  packageConfig: PackageConfig;
  pack: Package;
  amountToPay?: AmountToPay;
  numberOfHighlightItems?: number;
  numberOfVisibleItems?: number;
  showMarketing?: boolean;
  isCopyExperiment?: boolean;
};

type ListItemProps = {
  title: string;
  description?: string;
  value?: string | boolean;
  onInfo?: ({ title, content }: { title: string; content: ReactNode }) => void;
  isCopyExperiment?: boolean;
};

const ListItem: FC<ListItemProps> = ({
  title,
  description,
  value,
  onInfo,
  isCopyExperiment,
}) => {
  const { t } = useWebTranslation();

  return (
    <FlexCol style={{ marginBottom: 8 }}>
      <FlexRow alignItems="center">
        <Image
          src={
            value
              ? "/images/icons/checkmark-green.svg"
              : "/images/icons/close-red.svg"
          }
          alt={t(value ? "global.yes" : "global.no")}
          width={20}
          height={20}
          style={{ display: "flex", marginTop: 4 }}
        />
        <Gutter horizontal size={1} />
        {isCopyExperiment ? (
          <Typography style={{ fontSize: 16 }}>{t(title)}</Typography>
        ) : (
          <div
            onClick={
              onInfo
                ? (e) => {
                    onInfo({
                      title: t(title),
                      content: (
                        <Typography style={{ whiteSpace: "pre-wrap" }}>
                          {t(description ?? "")}
                        </Typography>
                      ),
                    });
                    e?.stopPropagation();
                  }
                : () => null
            }
          >
            <Typography
              color={value ? "black" : "grey400"}
              style={
                onInfo
                  ? {
                      textDecoration: "underline",
                      textDecorationStyle: "dotted",
                      textDecorationColor: Colors.grey400,
                      textUnderlineOffset: 3,
                      cursor: "pointer",
                    }
                  : {}
              }
            >
              {t(title)}
            </Typography>
          </div>
        )}
      </FlexRow>
      {typeof value === "string" && (
        <>
          <Gutter size={1} />
          <FlexRow>
            <div style={{ height: 20, width: 20, flexShrink: 0 }}></div>
            <Gutter horizontal size={0.5} />
            <Typography color="grey500" style={{ whiteSpace: "pre-wrap" }}>
              {t(value)}
            </Typography>
          </FlexRow>
        </>
      )}
    </FlexCol>
  );
};

type ItemsProps = {
  items: { title: string; description?: string; value?: string | boolean }[];
  onInfo?: ({ title, content }: { title: string; content: ReactNode }) => void;
  visible: boolean;
  isCopyExperiment?: boolean;
};

const Items: FC<ItemsProps> = ({
  items,
  onInfo,
  visible,
  isCopyExperiment,
}) => {
  return (
    <FlexCol
      style={{
        marginTop: 6,
        ...(visible
          ? {
              maxHeight: "1500px",
              overflow: "hidden",
              transition: "max-height 0.5s ease-in",
            }
          : {
              maxHeight: 0,
              overflow: "hidden",
              transition: "max-height 0.5s ease-out",
            }),
      }}
    >
      {items.map((item) => (
        <ListItem
          key={item.title}
          onInfo={onInfo}
          isCopyExperiment={isCopyExperiment}
          {...item}
        />
      ))}
    </FlexCol>
  );
};

const commonCopyExperimentItems = (): Array<{
  title: string;
  value: boolean;
}> => {
  return [
    { title: "copy_exp.common.1", value: true },
    { title: "copy_exp.common.2", value: true },
    { title: "copy_exp.common.3", value: true },
    { title: "copy_exp.common.4", value: true },
    {
      title: "copy_exp.common.5",
      value: true,
    },
    { title: "copy_exp.common.6", value: true },
  ];
};

const copyExperiementPackageItems = (
  limit: number
): Array<{ title: string; value: boolean }> => {
  switch (limit) {
    case 3000000: {
      return [{ title: "copy_exp.common.7", value: true }];
    }
    case 6000000:
    case 16000000: {
      return [
        { title: "copy_exp.common.7", value: true },
        {
          title: "copy_exp.common.8",
          value: true,
        },
      ];
    }
    default: {
      return [];
    }
  }
};

const InsurancePackage = ({
  style,
  onPress,
  onInfo,
  onCompare,
  active,
  packageConfig,
  pack,
  amountToPay,
  numberOfHighlightItems = 0,
  showMarketing,
  isCopyExperiment,
  numberOfVisibleItems = isCopyExperiment ? 8 : showMarketing ? 6 : 3,
}: InsurancePackageProps) => {
  const { t } = useWebTranslation();

  const items = isCopyExperiment
    ? commonCopyExperimentItems()
    : showMarketing && pack.marketing?.usps
    ? pack.marketing.usps
    : packageConfig.benefits.map((benefit) => ({
        title: benefit.title,
        description: benefit.description,
        value: pack.benefitIncludes[benefit.name],
      }));

  return (
    <div
      onClick={onPress}
      className={styles.packageContainer}
      style={{
        ...style,
        cursor: onPress ? "pointer" : "inherit",
        borderColor: active ? Colors.blue300 : "white",
        backgroundColor: active ? Colors.navy100 : "white",
      }}
    >
      <PackageCard
        pack={pack}
        amountToPay={amountToPay}
        showMarketing={showMarketing}
      />
      <PackageCardContent>
        {items.slice(0, numberOfHighlightItems).map((item) => (
          <Fragment key={item.title}>
            <Typography variant="body3">
              <strong>{t(item.title)}</strong>
            </Typography>
            {typeof item.value === "string" && (
              <Typography variant="body1" color="grey500">
                <strong>{t(item.value as string)}</strong>
              </Typography>
            )}
          </Fragment>
        ))}
        {isCopyExperiment &&
          copyExperiementPackageItems(pack.limit).map((item) => {
            return (
              <div
                key={item.title}
                style={{ display: "flex", marginBottom: 10 }}
              >
                <Image
                  src={"/images/icons/checkmark-green.svg"}
                  alt={t("global.yes")}
                  width={20}
                  height={20}
                  style={{ display: "flex", marginTop: 4, marginRight: 8 }}
                />
                <Typography style={{ fontSize: 16 }}>
                  <Trans
                    i18nKey={item.title}
                    values={{ limit: formatCurrency(pack.limit / 100) }}
                    components={[<strong key="0" />]}
                  />
                </Typography>
              </div>
            );
          })}
        {isCopyExperiment && (
          <div
            style={{
              height: 1,
              backgroundImage: `linear-gradient(to right, ${Colors.grey300} 30%, rgba(255, 255, 255, 0) 0%)`,
              backgroundSize: "8px",
            }}
          />
        )}

        {numberOfHighlightItems > 0 && <Gutter size={2} />}
        <Items
          items={items.slice(numberOfHighlightItems, numberOfVisibleItems)}
          onInfo={onInfo}
          visible
          isCopyExperiment={isCopyExperiment}
        />
        <div style={{ marginTop: "auto" }} />
        <Gutter size={2} />
        {!isCopyExperiment && (
          <div
            style={{
              height: 1,
              backgroundImage: `linear-gradient(to right, ${Colors.grey300} 30%, rgba(255, 255, 255, 0) 0%)`,
              backgroundSize: "8px",
            }}
          />
        )}
        <Gutter size={2} />
        <FlexRow
          alignItems="center"
          justifyContent="center"
          onClick={(e) => {
            onCompare();
            e?.stopPropagation();
          }}
          style={{ padding: "8px 16px" }}
        >
          <Typography
            variant="body3"
            color="grey500"
            style={{ cursor: "pointer" }}
          >
            <strong>{t("insurance_packages.all_benefits")}</strong>
          </Typography>
        </FlexRow>
        {onPress && (
          <>
            <Gutter size={1} />
            <Button
              onPress={() => onPress()}
              title={active ? t("global.selected") : t("global.select")}
              variant={active ? "outlined" : "custom"}
              color={active ? "white" : "blue100"}
              textColor="blue300"
              iconFirst
              icon={
                active && (
                  <Image
                    src="/images/icons/checkmark-blue.svg"
                    alt={t("global.yes")}
                    width={20}
                    height={20}
                  />
                )
              }
            />
          </>
        )}
      </PackageCardContent>
    </div>
  );
};

export default InsurancePackage;
